import { useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { DateFormats, formatDate } from '~/shared/helpers/date';

import { PageHeader } from '~/services/layouts';

import { useCustomMilkingReportDetailedFromCacheOrQuery } from '~/entities/customMilkingReports';

import {
  CustomMilkingReportCard,
  isCustomMilkingReportDataEmpty,
} from '~/features/customMilkingReport';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/milking/by-herd/$customMilkingReportId'
)({
  component: CustomMilkingReportPage,
});

function CustomMilkingReportPage() {
  const { customMilkingReportId } = Route.useParams();

  const {
    fragment: customMilkingReportFragment,
    isLoading: isCustomMilkingReportLoading,
    refetch,
    isRefetching,
  } = useCustomMilkingReportDetailedFromCacheOrQuery(customMilkingReportId);

  const customMilkingReport =
    customMilkingReportFragment ?? getSingleSkeletonPlaceholder();

  const [reportKey, setReportKey] = useState(Math.random());

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          isLoading: isCustomMilkingReportLoading,
          title: customMilkingReport.name,
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
            params: true,
          },
          rightContent: !isCustomMilkingReportDataEmpty(
            customMilkingReport?.calculatedReport
          ) && (
            <div className="flex items-center gap-16">
              <Typography
                className="text-soft"
                variant={TypographyVariants.bodySmall}
              >
                Составлен по данным от&nbsp;
                {formatDate(
                  customMilkingReport.calculatedReport?.cacheCreatedAt,
                  DateFormats.shortWithTime
                )}
              </Typography>
              <Button
                {...{
                  iconVariant: IconVariants.update,
                  variant: ButtonVariants.secondary,
                  children: 'Обновить отчёт',
                  isDisabled: isCustomMilkingReportLoading,
                  isLoading: isRefetching,
                  onPress: () =>
                    refetch({
                      invalidateCache: true,
                    }).then(() => {
                      setReportKey(Math.random());
                    }),
                }}
              />
            </div>
          ),
        }}
      />
      <Skeleton isLoading={isCustomMilkingReportLoading}>
        <CustomMilkingReportCard
          key={reportKey}
          {...{
            customMilkingReport,
          }}
        />
      </Skeleton>
    </div>
  );
}
