import React from 'react';

import clsx from 'clsx';

import { Button } from '~/shared/components/Button';

import { Tab } from './components/Tab';
import styles from './index.module.scss';
import { TabsProps, TabsVariants } from './types';

export const Tabs: React.FC<TabsProps> = ({
  className,
  variant = TabsVariants.primary,
  children,
  tabs,
  buttonProps,
}) => {
  let tabsElement = (
    <div className={clsx(styles.root, styles[variant])}>
      {tabs.map(tabConfig => {
        return (
          <Tab
            key={tabConfig.href}
            {...{
              variant,
              tabConfig,
            }}
          />
        );
      })}
      {buttonProps && <Button className="ml-a" {...buttonProps} />}
    </div>
  );

  if (variant === TabsVariants.page) {
    tabsElement = <div className={className}>{tabsElement}</div>;
  }

  return (
    <>
      {tabsElement}
      {children}
    </>
  );
};

export * from './types';
