import { useEffect } from 'react';

import { ButtonPropsWithoutRef } from '~/shared/components/Button';

import { useRouterTabsContext } from '../components/RouterTabs';

interface UseLayoutStateProps {
  buttonProps?: ButtonPropsWithoutRef;
}

/**
 * Hook for controlling the upper level page layout from the nested pages
 */
export const useLayoutState = ({ buttonProps }: UseLayoutStateProps) => {
  const { routerTabsButtonProps, setRouterTabsButtonProps } =
    useRouterTabsContext();

  useEffect(() => {
    setRouterTabsButtonProps(buttonProps);

    return () => {
      // Return to initial mount value after unmount
      setRouterTabsButtonProps(routerTabsButtonProps);
    };
  }, []);
};

/**
 * Hacky component to allow to conditionally reset layout state,
 * such as in case, when we have an AsyncList with no items, and
 * we need to render the button at the center of the page, instead of in the layout.
 *
 * TODO rework AsyncList component, to decouple it from filters, when we create actual Filters component
 */
export const LayoutStateReset = () => {
  useLayoutState({
    buttonProps: undefined,
  });

  return null;
};
