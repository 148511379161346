import { useEffect, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { DefaultContext } from '@apollo/client';

import R from 'ramda';

import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  useSkeletonableState,
  useSkeletonContext,
} from '~/shared/components/Skeleton';

import {
  ReportCardBuilderCardProps,
  ReportCardBuilderCardStates,
} from '../components/ReportCardBuilderCard';
import {
  InferPossibleUntrackedFields,
  ReportCardBuilderSettingsFormProps,
} from '../types';

interface UseReportCardBuilderProps<
  ReportDeps extends [unknown, ...unknown[]],
  ReportData,
  ReportSettings,
  ReportSettingsFormType extends FieldValues,
  UntrackedField extends
    InferPossibleUntrackedFields<ReportSettingsFormType> = '',
> {
  /**
   * Objects, on which depends report data calculation, they also may be skeletons,
   * so the hook will handle their loading and setting correct reportData state
   */
  reportDeps: ReportDeps;

  /**
   * This getter should return report data based on reportDeps.
   * If undefined is returned, renders a SkeletonPlaceholder
   */
  getReportData: (reportDeps: ReportDeps) => ReportData | undefined;

  /**
   * This getter should return report settings based on reportDeps.
   * If undefined is returned, renders a SkeletonPlaceholder
   */
  getReportSettings: (
    reportDeps: ReportDeps
  ) => ReportSettings | undefined | SkeletonPlaceholder;
  /**
   * Called to transform ReportSettings into form values
   */
  mapReportSettingsToForm: (
    reportSettings: ReportSettings | SkeletonPlaceholder
  ) => ReportSettingsFormType;
  /**
   * Array of fields, changes in which doesn't trigger the onSettingsFormChange event
   */
  untrackedFields?: UntrackedField[];

  /**
   * This getter should return true, if the report data is empty
   */
  getIsReportEmpty: (
    reportData: ReportData | SkeletonPlaceholder,
    reportDeps: ReportDeps
  ) => boolean;
  /**
   * Value to set as report data, if calculate report mutation didn't return any value
   */
  emptyReportData: NoInfer<ReportData>;

  /**
   * Action to call a mutation for report calculation
   */
  calculateReport: (
    settingsFormValues: Omit<ReportSettingsFormType, UntrackedField>,
    mutationContext: DefaultContext
  ) => Promise<void>;
  /**
   * Action to call save report settings mutation
   */
  setReportSettings: (
    settingsForm: ReportSettingsFormType
  ) => Promise<unknown> | undefined;

  /**
   * Called, when card editing is cancelled but cancel button
   */
  onCardEditCancel?: () => void;

  /**
   * If true, uses create card state as initial, update otherwise
   */
  withCreateState?: boolean;
}

/**
 * Hook for reusing ux patterns for creating report constructors like in custom reports
 */
export const useReportCardBuilder = <
  ReportDeps extends [unknown, ...unknown[]],
  ReportData,
  ReportSettings,
  ReportSettingsFormType extends FieldValues,
  UntrackedField extends
    InferPossibleUntrackedFields<ReportSettingsFormType> = '',
>({
  reportDeps,

  getReportData,

  getReportSettings,
  mapReportSettingsToForm,
  untrackedFields = [],

  getIsReportEmpty,
  emptyReportData,

  calculateReport,
  setReportSettings,

  onCardEditCancel,

  withCreateState = false,
}: UseReportCardBuilderProps<
  ReportDeps,
  ReportData,
  ReportSettings,
  ReportSettingsFormType,
  UntrackedField
>) => {
  const initialEditingCardState = withCreateState
    ? ReportCardBuilderCardStates.create
    : ReportCardBuilderCardStates.update;

  const { isLoading } = useSkeletonContext();

  const abortControllerRef = useRef<AbortController>();

  const reportDataFromGetter = getReportData(reportDeps);
  const initialReportData =
    reportDataFromGetter === undefined
      ? getSingleSkeletonPlaceholder()
      : reportDataFromGetter;

  const reportSettingsFromGetter = getReportSettings(reportDeps);
  const initialSettings = isSkeletonPlaceholder(reportSettingsFromGetter)
    ? undefined
    : reportSettingsFromGetter;
  const initialSettingsForm = mapReportSettingsToForm(
    initialSettings ?? getSingleSkeletonPlaceholder()
  );

  const isEmptyReport = getIsReportEmpty(
    initialReportData ?? emptyReportData,
    reportDeps
  );

  const [
    currentReportData,
    setCurrentReportData,
    setCurrentReportDataToSkeleton,
  ] = useSkeletonableState(initialReportData);

  const [requestedSettings, setRequestedSettings] = useState(
    R.omit(untrackedFields, initialSettingsForm)
  );

  // State for default data, cause we might have multiple calculations, but we don't mutate original reportDeps
  const [defaultData, setDefaultData] = useState(initialReportData);

  const [cardState, setCardState] = useState(
    !isLoading && isEmptyReport
      ? initialEditingCardState
      : ReportCardBuilderCardStates.view
  );

  // Update report data from skeleton, after we loaded reportData
  useEffect(() => {
    if (!reportDeps.some(isSkeletonPlaceholder)) {
      setDefaultData(initialReportData);
      setCurrentReportData(initialReportData);
      setRequestedSettings(initialSettingsForm);
    }
  }, [...reportDeps]);

  // Set editing state, if we loaded empty data
  useEffect(() => {
    if (isEmptyReport && !isLoading) {
      setCardState(initialEditingCardState);
    }
  }, [isEmptyReport, isLoading]);

  const customReportCardProps: Pick<
    ReportCardBuilderCardProps,
    'cardState' | 'onCardStateChange'
  > = {
    cardState,
    onCardStateChange: newCardState => {
      // Cancel is pressed
      if (newCardState === ReportCardBuilderCardStates.view) {
        abortControllerRef.current?.abort();
        onCardEditCancel?.();
        setRequestedSettings(initialSettingsForm);
        setCurrentReportData(defaultData);
      }
      setCardState(newCardState);
    },
  };

  const customReportSettingsFormProps: ReportCardBuilderSettingsFormProps<
    ReportSettingsFormType,
    UntrackedField
  > = {
    onSettingsFormChange: settingsFormValues => {
      abortControllerRef.current?.abort();

      abortControllerRef.current = new AbortController();

      setCurrentReportDataToSkeleton();

      calculateReport(settingsFormValues, {
        fetchOptions: {
          signal: abortControllerRef.current.signal,
        },
      })
        .then(() => {
          setRequestedSettings(settingsFormValues);
        })
        .catch(err => {
          // Don't set empty state if we aborted the request with a new one
          if (err?.cause?.name === 'AbortError') {
            return;
          }

          setCurrentReportData(
            emptyReportData ?? getSingleSkeletonPlaceholder()
          );
        });
    },
    onSettingsFormSave: settingsForm => {
      setReportSettings(settingsForm)?.then(() => {
        setDefaultData(currentReportData);
        setCardState(ReportCardBuilderCardStates.view);
      });
    },
  };

  return {
    initialReportData: initialReportData ?? emptyReportData,
    currentReportData: currentReportData ?? emptyReportData,
    setCurrentReportData,

    initialSettings,
    requestedSettings,

    customReportCardProps,
    customReportSettingsFormProps,
  };
};
