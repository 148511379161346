import { MilkingHistoricFilterKind } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_HISTORIC_KINDS_DICT } from '../../../../constants';

const useMilkingHistoricFilterKindSelect = makeUseEnumSelect(
  MilkingHistoricFilterKind,
  enumValue =>
    CUSTOM_MILKING_REPORT_HISTORIC_KINDS_DICT[
      enumValue as MilkingHistoricFilterKind
    ]
);

export const MilkingHistoricFilterKindSelect = makeSelectComponentFromHook(
  useMilkingHistoricFilterKindSelect
);
