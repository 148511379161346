import R from 'ramda';

import { DEFAULT_LOCALE, DEFAULT_PRECISION } from '~/shared/constants';

/**
 * Type used in formatters with number-like input
 */
type FormattableNumberValue = string | number | null | undefined;

/**
 * Normalizes a given value from within known initial range min and max to result range
 */
export const normalizeBetweenTwoRanges = (
  value: number,
  initRangeMin: number,
  initRangeMax: number,
  resRangeMin: number,
  resRangeMax: number
) =>
  ((value - initRangeMin) / (initRangeMax - initRangeMin || 1)) *
    (resRangeMax - resRangeMin) +
  resRangeMin;

/**
 * Check, if number-like is empty, so we shouldn't do any formatting
 */
const shouldFormatNumberToEmptyString = (num: FormattableNumberValue) =>
  R.isNil(num) || num === '';

// Memoizing used formatters significantly improves performance in large tables
const intlMap = new Map<number | Intl.NumberFormatOptions, Intl.NumberFormat>();
/**
 * Format number like -900000.99 -> -900 000,99
 */
export const formatNumber = (
  num: FormattableNumberValue,
  precisionOrOptions: number | Intl.NumberFormatOptions = DEFAULT_PRECISION
) => {
  if (shouldFormatNumberToEmptyString(num)) {
    return '';
  }

  const number = Number(num || 0);

  if (Number.isNaN(number)) {
    return '';
  }

  const formatOptions =
    typeof precisionOrOptions === 'number'
      ? {
          minimumFractionDigits: precisionOrOptions,
          maximumFractionDigits: precisionOrOptions,
        }
      : precisionOrOptions;

  let intl = intlMap.get(precisionOrOptions);
  if (!intl) {
    intl = new Intl.NumberFormat(DEFAULT_LOCALE, formatOptions);
    intlMap.set(precisionOrOptions, intl);
  }

  return intl.format(number);
};

/**
 * Format integer number like 9000 -> 9 000
 */
export const formatInt = R.partialRight(formatNumber, [0]);

/**
 * Format number with percent like 99.3445 -> 99.34%, 99 -> 99%
 */
export const formatWithPercent = (
  num: FormattableNumberValue,
  precision = 0
) => {
  if (shouldFormatNumberToEmptyString(num)) {
    return '';
  }
  return `${formatNumber(num, precision)}%`;
};

/**
 * Format number with currency like -900000.99 -> -900 000,99 ₽
 */
export const formatCurrency = (formattableCurrency: FormattableNumberValue) => {
  if (shouldFormatNumberToEmptyString(formattableCurrency)) {
    return '';
  }
  const currency = Number(formattableCurrency || 0);

  const intl = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'narrowSymbol',
  });
  return intl.format(currency);
};
