import R from 'ramda';
import { match, P } from 'ts-pattern';

import { getEventId } from '~/entities/events';

import { COW_EVENT_CATEGORY_CONFIGS } from './constants';
import { CowDefaultEventFragment } from './gql/fragments/cowDefaultEvent.graphql';
import { CowDiseaseFragment } from './gql/fragments/cowDisease.graphql';
import { CowEventFragment } from './gql/fragments/cowEvent.graphql';
import { CowInjectionFragment } from './gql/fragments/cowInjection.graphql';
import { CowProtocolFragment } from './gql/fragments/cowProtocol.graphql';
import { CowUserEventFragment } from './gql/fragments/cowUserEvent.graphql';
import { CowEventCategories, CowNormalizedEvent } from './types';

/**
 * Checks, if an event is CowDefaultEvent
 */
export const isCowDefaultEvent = (
  event: CowEventFragment
): event is CowEventFragment & CowDefaultEventFragment =>
  event.__typename === 'CowDefaultEvent';

/**
 * Checks, if an event is CowDiseaseFragment
 */
export const isCowDisease = (
  event: CowEventFragment
): event is CowEventFragment & CowDiseaseFragment =>
  event.__typename === 'CowDisease';

/**
 * Checks, if an event is CowInjection
 */
export const isCowInjection = (
  event: CowEventFragment
): event is CowEventFragment & CowInjectionFragment =>
  event.__typename === 'CowInjection';

/**
 * Checks, if an event is CowProtocol
 */
export const isCowProtocol = (
  event: CowEventFragment
): event is CowEventFragment & CowProtocolFragment =>
  event.__typename === 'CowProtocol';

/**
 * Checks, if an event is CowUserEvent
 */
const isCowUserEvent = (
  event: CowEventFragment
): event is CowEventFragment & CowUserEventFragment =>
  event.__typename === 'CowUserEvent';

/**
 * Gets event id from a cow event
 */
export const getCowEventEventId = (cowEvent: CowEventFragment) =>
  match(cowEvent)
    .with(P.when(isCowDisease), matchedEvent => matchedEvent.disease.id)
    .with(P.when(isCowInjection), matchedEvent => matchedEvent.injection.id)
    .with(P.when(isCowProtocol), matchedEvent => matchedEvent.protocol.id)
    .with(P.when(isCowUserEvent), matchedEvent => matchedEvent.userEvent.id)
    .with(P.when(isCowDefaultEvent), matchedEvent =>
      getEventId(matchedEvent.event)
    )
    .exhaustive();

/**
 * Gets normalized cow event representation for rendering and categorization
 */
export const getNormalizedCowEvent = (
  originalCowEvent: CowEventFragment
): CowNormalizedEvent => {
  const pickKindAndName = R.pick(['kind', 'name']);

  const kindAndName = match(originalCowEvent)
    .with(P.when(isCowDisease), matchedEvent =>
      pickKindAndName(matchedEvent.disease)
    )
    .with(P.when(isCowInjection), matchedEvent =>
      pickKindAndName(matchedEvent.injection)
    )
    .with(P.when(isCowProtocol), matchedEvent =>
      pickKindAndName(matchedEvent.protocol)
    )
    .with(P.when(isCowUserEvent), matchedEvent =>
      pickKindAndName(matchedEvent.userEvent)
    )
    .with(P.when(isCowDefaultEvent), matchedEvent =>
      pickKindAndName(matchedEvent.event)
    )
    .exhaustive();

  const category =
    (Object.entries(COW_EVENT_CATEGORY_CONFIGS).find(([, eventConfig]) => {
      if (!eventConfig.shortcodes && !eventConfig.kinds) return false;

      if (eventConfig.shortcodes && 'event' in originalCowEvent) {
        return eventConfig.shortcodes.includes(
          originalCowEvent.event.shortcode
        );
      }

      return eventConfig.kinds?.includes(kindAndName.kind);
    })?.[0] as CowEventCategories | undefined) ?? CowEventCategories.other;

  const lactationNumber = isCowDefaultEvent(originalCowEvent)
    ? originalCowEvent.lactation?.number
    : null;

  return {
    ...kindAndName,
    category,
    config: COW_EVENT_CATEGORY_CONFIGS[category],
    originalCowEvent,
    lactationNumber,
  };
};
