import * as Types from '../../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ConceptionRateChartBullCandleFragmentDoc } from '../fragments/conceptionRateChartBullCandle.graphql';
import { ConceptionRateChartDaysInMilkCandleFragmentDoc } from '../fragments/conceptionRateChartDaysInMilkCandle.graphql';
import { ConceptionRateChartInseminationNumberCandleFragmentDoc } from '../fragments/conceptionRateChartInseminationNumberCandle.graphql';
import { ConceptionRateChartStudCodeCandleFragmentDoc } from '../fragments/conceptionRateChartStudCodeCandle.graphql';
import { ConceptionRateChartInseminationSchemeCandleFragmentDoc } from '../fragments/conceptionRateChartInseminationSchemeCandle.graphql';
import { ConceptionRateChartEmployeeCandleFragmentDoc } from '../fragments/conceptionRateChartEmployeeCandle.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConceptionRateByParameterChartQueryVariables = Types.Exact<{
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  parameter: Types.ConceptionRateParameterEnum;
}>;


export type ConceptionRateByParameterChartQuery = { __typename?: 'Query', analytics: { __typename?: 'Analytics', conceptionRate: { __typename?: 'ConceptionRate', candles: Array<{ __typename: 'ConceptionRateChartStudCodeCandle', meanValue: number, maxValue: number, studCode: string } | { __typename: 'ConceptionRateChartInseminationNumberCandle', meanValue: number, maxValue: number, inseminationNumber: number } | { __typename: 'ConceptionRateChartInseminationSchemeCandle', meanValue: number, maxValue: number, inseminationScheme?: { __typename: 'InseminationScheme', id: string, name: string } | null } | { __typename: 'ConceptionRateChartDaysInMilkCandle', meanValue: number, maxValue: number, daysInMilk: number } | { __typename: 'ConceptionRateChartBullCandle', meanValue: number, maxValue: number, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } } | { __typename: 'ConceptionRateChartEmployeeCandle', meanValue: number, maxValue: number, employee?: { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string } | null }> } } };


export const ConceptionRateByParameterChartDocument = gql`
    query conceptionRateByParameterChart($farmIDs: [ID!], $parameter: ConceptionRateParameterEnum!) {
  analytics(farmIDs: $farmIDs) {
    conceptionRate(parameter: $parameter) {
      candles {
        ...ConceptionRateChartBullCandle
        ...ConceptionRateChartDaysInMilkCandle
        ...ConceptionRateChartInseminationNumberCandle
        ...ConceptionRateChartStudCodeCandle
        ...ConceptionRateChartInseminationSchemeCandle
        ...ConceptionRateChartEmployeeCandle
      }
    }
  }
}
    ${ConceptionRateChartBullCandleFragmentDoc}
${ConceptionRateChartDaysInMilkCandleFragmentDoc}
${ConceptionRateChartInseminationNumberCandleFragmentDoc}
${ConceptionRateChartStudCodeCandleFragmentDoc}
${ConceptionRateChartInseminationSchemeCandleFragmentDoc}
${ConceptionRateChartEmployeeCandleFragmentDoc}`;

/**
 * __useConceptionRateByParameterChartQuery__
 *
 * To run a query within a React component, call `useConceptionRateByParameterChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useConceptionRateByParameterChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConceptionRateByParameterChartQuery({
 *   variables: {
 *      farmIDs: // value for 'farmIDs'
 *      parameter: // value for 'parameter'
 *   },
 * });
 */
export function useConceptionRateByParameterChartQuery(baseOptions: Apollo.QueryHookOptions<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables> & ({ variables: ConceptionRateByParameterChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>(ConceptionRateByParameterChartDocument, options);
      }
export function useConceptionRateByParameterChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>(ConceptionRateByParameterChartDocument, options);
        }
export function useConceptionRateByParameterChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>(ConceptionRateByParameterChartDocument, options);
        }
export type ConceptionRateByParameterChartQueryHookResult = ReturnType<typeof useConceptionRateByParameterChartQuery>;
export type ConceptionRateByParameterChartLazyQueryHookResult = ReturnType<typeof useConceptionRateByParameterChartLazyQuery>;
export type ConceptionRateByParameterChartSuspenseQueryHookResult = ReturnType<typeof useConceptionRateByParameterChartSuspenseQuery>;
export type ConceptionRateByParameterChartQueryResult = Apollo.QueryResult<ConceptionRateByParameterChartQuery, ConceptionRateByParameterChartQueryVariables>;