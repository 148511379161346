import React from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { SelectThemes } from '~/shared/components/Select';

import { emptySwitchableFormFields } from '~/services/forms';
import { ReportCardBuilderFormArrayItem } from '~/services/reportCardBuilder';

import { BlueprintSourceFieldAsyncSelect } from '~/entities/blueprintSourceFields';

import {
  CustomMilkingReportGroupingFormType,
  CustomMilkingReportSettingsFormType,
  FiltersAndGroupingsModes,
} from '../../../../types';
import { useFiltersAndGroupingsModeItems } from '../../hooks/useFiltersAndGroupingsModeItems';
import { MilkingHistoricFilterKindSelect } from '../MilkingHistoricFilterKindSelect';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Y axis field config to render
   */
  groupingConfig: CustomMilkingReportGroupingFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
}

export const GroupingConfigArrayItemCard: React.FC<Props> = ({
  className,
  groupingConfig,
  onDelete,
}) => {
  const formContext = useFormContext<CustomMilkingReportSettingsFormType>();

  const groupingModeItems = useFiltersAndGroupingsModeItems();

  const selectedGroupingMode = groupingConfig.mode;

  const isActualGroupingMode =
    selectedGroupingMode === FiltersAndGroupingsModes.actual;

  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: 'Группировка по значениям',
        onDelete,
      }}
    >
      <RadioGroup
        {...{
          name: 'grouping.mode',
          variant: RadioGroupVariants.segmented,
          items: groupingModeItems,
          value: selectedGroupingMode,
          onValueChange: newValue => {
            emptySwitchableFormFields(
              formContext,
              newValue === FiltersAndGroupingsModes.actual,
              {
                'grouping.masterBlueprintSourceFieldID': null,
              },
              {
                'grouping.kind': null,
              }
            );
          },
        }}
      />
      {isActualGroupingMode && (
        <BlueprintSourceFieldAsyncSelect
          {...{
            name: 'grouping.masterBlueprintSourceFieldID',
            label: 'Параметр группировки',
            theme: SelectThemes.light,
            queryOptions: {
              variables: {
                onlyMaster: true,
              },
            },
          }}
        />
      )}
      {!isActualGroupingMode && (
        <MilkingHistoricFilterKindSelect
          {...{
            name: 'grouping.kind',
            label: 'Формула группировки',
            theme: SelectThemes.light,
          }}
        />
      )}
    </ReportCardBuilderFormArrayItem>
  );
};
