import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { AsyncList } from '~/shared/components/AsyncList';
import { ButtonProps } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';
import { SerializableSearchParams } from '~/shared/types/serialization';

import {
  LayoutStateReset,
  useLayoutState,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { BlueprintRoleAsyncSelect } from '~/entities/blueprintRoles';
import {
  useCustomMilkingReportsPaginatedQuery,
  useEditCustomMilkingReportModal,
} from '~/entities/customMilkingReports';
import { CustomMilkingReportFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReport.graphql';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { CustomMilkingReportCard } from './components';

const CUSTOM_MILKING_REPORT_CARDS_DEFAULT_COUNT = 4;

interface AnalyticsMilkingByHerdPageSearchParams
  extends SerializableSearchParams {
  blueprintRoleIds: string[];
  search: string;
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/milking/by-herd'
)({
  component: AnalyticsMilkingByHerdPage,
  validateSearch: ({
    blueprintRoleIds,
    search,
  }: WithSearchParamsValidation<AnalyticsMilkingByHerdPageSearchParams>) => {
    return {
      blueprintRoleIds: blueprintRoleIds ?? [],
      search: search ?? '',
    };
  },
});

function AnalyticsMilkingByHerdPage() {
  const {
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
    blueprintRoleIds,
    setBlueprintRoleIds,
  } = useSearchParamsState<AnalyticsMilkingByHerdPageSearchParams>();

  const { search, setSearch, isSearchActive } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const queryVariables = {
    roleIDs: blueprintRoleIds,
    search: debouncedSearch,
  };

  const { open: openEditCustomMilkingReportModal } =
    useEditCustomMilkingReportModal();

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Создать отчёт',
    onPress: () =>
      openEditCustomMilkingReportModal({
        queryVariables,
      }),
  } satisfies ButtonProps;

  useLayoutState({
    buttonProps: addButtonProps,
  });

  const { items: customMilkingReportItems, ...asyncProps } =
    useCustomMilkingReportsPaginatedQuery({
      variables: queryVariables,
    });

  const filtersElement = (
    <div
      className={clsx(
        'flex items-end justify-between gap-16 mb-24',
        layoutStyles.limitedContainer
      )}
    >
      <BlueprintRoleAsyncSelect
        {...{
          isMulti: true,
          className: 'default-control',
          name: 'blueprintRoles',
          rawValue: blueprintRoleIds,
          theme: SelectThemes.light,
          onValueChange: newRoleIds =>
            setBlueprintRoleIds(newRoleIds.map(R.prop('id'))),
          isUsedAsFilter: true,
        }}
      />
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск по названию',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
          theme: InputThemes.light,
        }}
      />
    </div>
  );

  return (
    <AsyncList<CustomMilkingReportFragment>
      {...{
        className: clsx(
          layoutStyles.limitedContainer,
          contentGridStyles.autoGridDynamicItems
        ),
        wrapperTag: 'div',
        items: customMilkingReportItems,
        skeletonItemsCount: CUSTOM_MILKING_REPORT_CARDS_DEFAULT_COUNT,
        shouldWrapNoItemsMessage: false,
        isSearchActive: isSearchActive || !R.isEmpty(blueprintRoleIds),
        filtersElement,
        noFiltersElement: <LayoutStateReset />,
        renderNoItemsMessageWrapper: noItemsMessage => (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            {noItemsMessage}
          </div>
        ),
        noItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Отчёты по надоям стада пока не созданы',
              buttonProps: addButtonProps,
            }}
          />
        ),
        noSearchItemsMessage: (
          <DataBlockedMessage
            {...{
              isLarge: true,
              className: 'p-24',
              message: 'Отчёты не найдены',
              description:
                'По вашему запросу нет подходящих отчётов. Используйте другое название',
            }}
          />
        ),
        renderItem: customMilkingReport => (
          <CustomMilkingReportCard
            key={customMilkingReport.id}
            {...{
              customMilkingReport,
              queryVariables,
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
}
