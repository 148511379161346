import React, { ReactNode } from 'react';

import { ButtonPropsWithoutRef } from '~/shared/components/Button';

import { AnyRouteItem } from '~/services/navigation';

/**
 * Possible tabs display variants
 */
export enum TabsVariants {
  primary = 'primary',
  secondary = 'secondary',
  page = 'page',
}

/**
 * Single tab item config
 */
export interface TabConfig {
  /**
   * Title to display on tab
   */
  title: ReactNode;
  /**
   * If passed, the tab is rendered as a link
   */
  href: AnyRouteItem;
}

/**
 * Tabs component props
 */
export interface TabsProps<Config extends TabConfig = TabConfig>
  extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Visual display variant of the tabs
   */
  variant?: TabsVariants;

  /**
   * Tab configs for rendering
   */
  tabs: Config[];
  /**
   * Props fo rendering a button to the right of the tabs
   */
  buttonProps?: ButtonPropsWithoutRef;

  /**
   * Key of selected tab
   */
  selectedTab?: string;
  /**
   * Key of selected tab
   */
  defaultSelectedTab?: string;
  /**
   * Called, when selected tab changes
   */
  onSelectTab?: (tabKey: string) => void;
}
