import { createContext, useContext } from 'react';

import { ButtonPropsWithoutRef } from '~/shared/components/Button';

/**
 * Context type for a router tabs context
 */
interface RouterTabsContextType {
  routerTabsButtonProps?: ButtonPropsWithoutRef;
  setRouterTabsButtonProps: (props: ButtonPropsWithoutRef | undefined) => void;
}

/**
 * Context for controlling upper level layout from the nested pages
 */
export const RouterTabsContext = createContext<RouterTabsContextType>({
  routerTabsButtonProps: undefined,
  setRouterTabsButtonProps: () => {},
});

/**
 * Hook for using the router tabs context, so the nested pages can control the layout
 */
export const useRouterTabsContext = () => useContext(RouterTabsContext);
